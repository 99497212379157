import React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import SearchConfirm from '../views/search_confirm';
import Branding from '../views/branding';
import College from '../views/college';
import Settings from '../views/settings';
import Recruitment from '../views/recruitment';
import ProfilePage from '../views/profile-page';
import Jobs from '../views/jobs';
import Home from '../views';
import Redirect from 'react-router-dom/Redirect';
import reports from '../views/reports';

export const Admin = (props) => {
  return (
    <Switch>
      <Route exact path="/profile" component={ProfilePage} />
      <Route exact path="/search/confirm" component={SearchConfirm} />
      <Route exact path="/branding" component={Branding} />
      <Route exact path="/jobs" component={Jobs} />
      <Route exact path="/reports" component={reports} />
      <Route exact path="/college" component={College} />
      <Route exact path="/recruitment" component={Recruitment} />
      <Route exact path="/recruitment/:recruiter_process_id" component={Recruitment} />
      <Route exact path="/settings" component={Settings} />
      <Route render={() => <Redirect to="/jobs" />} />
    </Switch>
  )
}
