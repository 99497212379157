export const API_CONFIG = {
  BASE_URL: process.env.REACT_APP_BASE_URL || "https://beta-recruiter.reculta.com",
  CAMPUS_URL: process.env.REACT_APP_CAMPUS_URL || "https://staging.reculta.com",
  BASE_PATH: "/api/v1",
}
export const ENDPOINTS = {
  USER: {
    LOGIN: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/user/login`,
    GET_USER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/get`,
    GET_CAMPUS_USER: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/user`,
    CREATE_RECRUITER: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/account/create`,
    SIGNUP: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/user/signup`,
    FORGOTPASSWORD: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/user/forgot/password`,
    RETRIEVEPASSWORD: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/user/retrieve/password`,
    LOGOUT: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/user/logout`,
    UPDATE_USER: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/user/profile/update`,
    EMAIL_TOKEN_GENERATE : `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/user/email/verification/create`,
    EMAIL_TOKEN_VERIFY : `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/user/email/token/verify`,
    UPLOAD_FILE: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/file/upload`,
    ADD_TO_FILE_MANAGER: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/file/create`,
    UPDATE_FILE: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/file/update`,
    GET_FILE_URL: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/file/signed/url`,
    GET_FILES: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/file`,
    CREATE_USER_ROLE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/role/create`,
    GET_USER_ROLE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/role/fetch`,
    INACTIVE_USER_ROLE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/role/inactive`
  },
  GENERAL:{
    GET_DESIGNATION: (params)=> `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/college/designation/list?keyword=${params}`,
    GET_SECTOR: () => `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/sector/list`,
    GET_COMPANIES: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/company/list`,
    ADD_COMPANY: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/company/create`,
    GET_COMPANY_PROFILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/profile/fetch`,
    ADD_COMPANY_PROFILE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/profile/update`,
    GET_CAMPUS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/college/search/all`,
    GET_CAMPUS_WITH_UNIV : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/college/search/all/university`,
    GET_PERMISSION : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/get/permissions`,
    GET_ADJECENT_CAMPUS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/adjacent-campus/fetch`,
    FETCH_CAMPUS_DEGREE_DEPARTMENT: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/campus/degree/list`,
    FETCH_CAMPUS_BATCHES: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/batch`,
  },
  RECRUITER:{
    GET_DESIGNATION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/college/designation/list`,
    GET_APPLICANTS: (params)=> `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/process/company/profile/application/hrlist/applicants?token=${params}`,
    GET_SPECIFIC_APPLICANTS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/applicants/fetch`,
    GET_COLLEGES: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/college/search`,
    CREATE_POST: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/post/recruiter/create`,
    GET_POST: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/post`,
    ASSIGN_ACCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/access/assign`,
    GET_ACCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/access/get`,
    INACTIVE_ACCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/access/inactive`,
    ALTER_PERMISSION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/permission/alter`,
    GET_PERMISSION: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/recruiter/permission/get`,
  },
  PROJECT: {
    CREATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/project/create`,
    ADD_CAMPUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/add`,
    GET_PROJECT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/project/fetch`,
    GET_CAMPUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/college/search/all`
  },
  PROCESS: {
    GET_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/fetch`,
    CREATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/add`,
    ADD_CAMPUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/campus/add`,
    GET_CAMPUS_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/campus/fetch`,
    GET_CAMPUS_ALL_PROCESS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/campus/product/view`,
    DELETE_CAMPUS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/campus/inactive`,
    CREATE_CAMPUS_JOB: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/create`,
    GET_CAMPUS_JOB: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/fetch`,
    UPDATE_CAMPUS_JOB: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/update`,
    DELETE_CAMPUS_JOB: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/inactive`,
    GET_APPLICANTS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/applicants/fetch`,
    SHORTLIST_UPDATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/applicants/shortlist/update`,
    SHORTLIST_RELEASE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/applicants/shortlist/release`,
    GET_CAMPUS_PROCESS_STUDENTS: `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/process/studentmapping/list`,
    ADD_JOB_INVITE_REFERENCE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/process/campus/selected/add`,
  },
  JOB_TEMPLATE:{
    FETCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job/fetch`,
    CREATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job/create`,
    UPDATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job/update`,
    INACTIVE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job/inactive`,
    DOCS_FETCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_docs/fetch`,
    DOCS_CREATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_docs/create`,
    DOCS_UPDATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_docs/update`,
    DOCS_INACTIVE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_docs/inactive`,
    ROUNDS_FETCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_rounds/fetch`,
    ROUNDS_CREATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_rounds/create`,
    ROUNDS_UPDATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_rounds/update`,
    ROUNDS_INACTIVE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_rounds/inactive`,
    ELIGIBILITY_FETCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_eligibility/fetch`,
    ELIGIBILITY_CREATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_eligibility/create`,
    ELIGIBILITY_UPDATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_eligibility/update`,
    ELIGIBILITY_INACTIVE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_eligibility/inactive`,
    QUESTION_FETCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_question/fetch`,
    QUESTION_CREATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_question/create`,
    QUESTION_UPDATE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_question/update`,
    QUESTION_INACTIVE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job_question/inactive`,
    FETCH_STATISTICS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/job/statistics/fetch`,
  },
  CONNECTION:{
    REQUEST: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/connection/request`,
    FETCH: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/connection/fetch`,
    INACTIVE: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/connection/inactive`,
  },
  SMART_SCORING : {
    GET_SMART_SCORING : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/student/score`
  },
  EVALUATION : {
    ASSIGN_JOB_TO_EVALUATOR : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/evaluation/job/access/assign`,
    GET_EVALUATOR_ACCESS_DETAILS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/evaluation/job/access/fetch`,
    REMOVE_EVALUATOR_ACCESS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/evaluation/job/access/retain`,
    ASSIGN_APPLICANTS_TO_EVALUATOR : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/evaluation/job/applicants/assign`,
    REMOVE_APPLICANTS_FROM_EVALUATOR : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/evaluation/job/applicants/retain`,
    FETCH_EVALUATOR_APPLICANTS : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/evaluation/job/applicants/fetch`,
    FETCH_CV : `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/student/cv/download`,
    COMMENT_UPDATE : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/evaluation/job/applicants/status/update`,
    COMMENT_UPDATE_FETCH : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/evaluation/job/applicants/fetch`,
  },
  EVALUATE: {
    GET_JOBS: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/evaluation/job/access/fetch`
  },
  DOWNLOAD : {
    CV : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/download/cv`,
    ANSWERS  : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/download/excel`,
    STUDENT_DATA : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/campus/job/download/data`
  },
  CAMPUS_PROFILE : {
    FETCH:`${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/campus/profile` ,
    
  },
  CAMPUS_CONVERSATION : {
    FETCH : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/conversation/read/recruiter` ,
    SEND : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/conversation/send/recruiter` ,
    MSG_COUNT: `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/conversation/count/unread` ,
  },
  POST : {
    SEND_POST : `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/post/recruiter/send`,
    VIEW_POST : `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/post/recruiter/view` ,
    INACTIVE_POST : `${API_CONFIG.CAMPUS_URL}${API_CONFIG.BASE_PATH}/post/recruiter/edit`,
  },
  REPORT : {
    VIEW_REPORT : `${API_CONFIG.BASE_URL}${API_CONFIG.BASE_PATH}/connection/report/view` ,

  }
}
