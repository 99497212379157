import React, { Component } from 'react'
import Table from 'reactstrap/lib/Table';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Card from 'reactstrap/lib/Card';
import Link from 'react-router-dom/Link';
import CardBody from 'reactstrap/lib/CardBody';
import Collapse from 'reactstrap/lib/Collapse';
import Aux from '../../global/AuxComp';
import Button from '../../global/Button'
import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import {JobModal} from '../jobs/job_modal'
import { Switch } from '../../global/Forms/ToggleSwitch';
import moment from 'moment';
import store from '../../../store';
import { setError, setLoading , setSuccess } from '../../../actions/action-creators';
import EvaluatorAllocation from './evaluator_allocation';
import {InputField} from '../../global/Forms';
import { Auth } from '../../../actions/Auth';
import { getErrorMessage , getFullName } from '../../../actions';
import { CONSTANTS } from '../../../constants';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink'
import classnames from 'classnames';

export default class JobDetail extends Component {

  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState= ()=>{
    return(
      {
       publish_status: false,
       isOpenJobEdit : false,
       selectTab : 0,
       inputValue : '',
       emailArray : [],
       evaluatorList : [],
       evaluatorInfoModal : false,
       activeTab : '1',
     }
    )
  }
  newJob = () => {
    return {
      job_template_id: "NEW_" + (Math.ceil(Math.random() * 10000)),
      location: "",
      application_eligibility: [],
      application_docs: [],
      application_question: [],
      ctc_max: "",
      ctc_min: "",
      ctc_type: "strict",
      ctc_currency: "INR",
      description: ""
    }
  }

  handleEmailInput = (e)=>{
    e.preventDefault();
    let {emailArray} = this.state,
    {email , firstName , lastName}  = e.target;
    let tempEmailArray = [...emailArray];
    if(!email.value || !firstName.value){
      store.dispatch(setError({ showBar: true, message : "Fill all the mandatory fields"}));
    }
    if(email.value && firstName.value){
      let check = this.checkEmailDuplicates(tempEmailArray  , email.value);
      if(check==1){
        tempEmailArray.push({email : email.value , first_name : firstName.value , last_name : lastName.value});
        this.setState({evaluatorInfoModal : false});
        document.getElementById("emailForm").reset();
        this.setState({emailArray : [...tempEmailArray]})
      }else{
         store.dispatch(setError({ showBar: true, message : "Email already added"}));
      }
    }
  }

  checkEmailDuplicates = (emailArray , email)=>{
    let flag = 1;
    emailArray.forEach(item=>{
      if(email == item.email){
        flag = 0;
      }
    })
    return flag;
  }

  deleteEvaluatorEmail = (email)=>{
    let {emailArray} = this.state;
    let tempEmailArray = [...emailArray];
    if(tempEmailArray.length>0){
      for(let i=0 ; i<tempEmailArray.length ; i++){
        if(tempEmailArray[i].email==email){
          tempEmailArray.splice(i , 1);
          i = i-1;
        }
      }
    }
    this.setState({emailArray : [...tempEmailArray]});
  }

  toggleEditJobModal = () => {
    this.setState({isOpenJobEdit : !this.state.isOpenJobEdit})
  }

  createEvaluatorAccount = async()=>{
    let {emailArray} = this.state ,
    {jobId} = this.props;
    store.dispatch(setLoading(true))
      await Promise.all(emailArray.map(async evaluator=>{
        try{
          let createEvaluatorResp = await Auth.User.signUp({email : evaluator.email, first_name : evaluator.first_name, last_name : evaluator.last_name, ...CONSTANTS.USER_TYPE});
          await Auth.User.createUserRole({user_id : createEvaluatorResp.data[0].id , role_id : 3});
          // await Auth.Evaluation.assignJobToEvaluator({user_id : createEvaluatorResp.data[0].id , campus_job_id : jobId })
          await this.assignEvaluatorAccess(createEvaluatorResp.data[0].id ,jobId );
          store.dispatch(setSuccess({ showBar: true, message: "Account/Accounts created successfully"}))
        }
        catch (e) {
          store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }));
        }
      }));
      this.setState(this.getInitialState());
      this.setState({selectTab : 1});
      this.getEvaluatorsList();
     store.dispatch(setLoading(false));
  }

  assignEvaluatorAccess = async(user_id , jobId)=>{
    try{
      await Auth.Evaluation.assignJobToEvaluator({user_id : user_id , campus_job_id : jobId })
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
  }

  removeEvaluatorAccess = async(evaluation_access_id)=>{
    try{
      await Auth.Evaluation.removeJobFromEvaluator({evaluation_access_id : evaluation_access_id});
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }));
    }
  }

  getEvaluatorsList = async()=>{
    store.dispatch(setLoading(true));
    let {jobId} = this.props;
    try{
      let evaluatorListResp  = await Auth.User.getUserRole({role_id : 3});
      let evalutatorAccessData = await Auth.Evaluation.getEvaluatorAccessDetails({campus_job_id : jobId});
      let evaluatorList =  evaluatorListResp.data.data.map(evaluator=>{
        let accessDetails = evalutatorAccessData.data.data.filter(data=> data.user_id == evaluator.user_id)[0] || {};
        evaluator.accessDetails = accessDetails;
        evaluator.assigned_applicants = 6;
        evaluator.Shortlisted = 6;
        return evaluator;
      }) || []
      this.setState({evaluatorList});
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }));
    }
    store.dispatch(setLoading(false));
  }

  handleStartevaluation = ()=>{
    this.setState({selectTab : 1});
  }


  handleEvaluatorAccess = (e)=>{
    let {jobId} = this.props;
    if(e.target.checked ==true){
      this.assignEvaluatorAccess(e.target.name , jobId );
    }
    else{
      this.removeEvaluatorAccess(e.target.value);
    }
    this.getEvaluatorsList();
  }

  componentDidMount(){
    this.getEvaluatorsList();
  }






  downloadCv = async()=>{
    let { applicationData, jobId } = this.props;

    let studentIdArray=applicationData.applied.map(o => o.student_id);

    let applicationID = null;
    if(applicationData.applicants.length){
      applicationID = applicationData.applicants[0].application_id;
    }

    let roleName;
    if(jobId) {
      try {
        let applicationResp = await Auth.Evaluate.getJobs({campus_job_id: jobId})
        if(applicationResp.data.data[0]) roleName = applicationResp.data.data[0].role;
      } catch(e) {
        console.log(e)
        // store.dispatch(setError({showBar: true, message: getErrorMessage(e)}))
      }
    }


    try{
      let downloadAnswersResp = await Auth.Download.cv({
        application_id : applicationID,
        student_id: studentIdArray,
        role_name : roleName,
        campus_id : this.props.campusID});
        store.dispatch(setSuccess({showBar: true, message: "Student CV download is in progress. We will send you a link on your email once it is complete."}))
    }
    catch(e){
      console.log(e);
    }
  }


  downloadAnswers = async()=>{

    let { applicationData } = this.props;

    let studentIdArray= applicationData.applied.map(o => o.student_id);

    let applicationID = null;

    if(applicationData.applicants.length){
      applicationID = applicationData.applicants[0].application_id;
    }

    try{
      let downloadAnswersResp = await Auth.Download.answers({
        application_id : applicationID ,
        student_id: studentIdArray 
      });
        const url = window.URL.createObjectURL(new Blob([downloadAnswersResp.data.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = 'answers.csv';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    catch(e){
      console.log(e);
    }
  }

  downloadExcel = async()=>{
    let { applicationData } = this.props;

    let studentIdArray=applicationData.applied.map(o => o.student_id);
    try{
      let downloadExcelResp = await Auth.Download.studentData({
          campus_id : this.props.campusID ,
          student_id: studentIdArray 
      });
        const url = window.URL.createObjectURL(new Blob([downloadExcelResp.data.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = 'students_data.csv';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    catch(e){
      console.log(e);
    }
  }








  render() {
    let { jobData, applicationData  , jobId , campusID} = this.props
    let { publish_status, isOpenJobEdit  , activeTab , selectTab , emailArray  , evaluatorInfoModal , evaluatorList} = this.state;
    let applicationID ;
    if(applicationData.applicants.length){
      applicationID = applicationData.applicants[0].application_id;
    }

    let roundWiseApplicants = (applicationData.applicants || []).reduce((prev, curr) => {
      (curr.shortlisting_data || []).map(o => {
        if(o.locked_status){
          if(!prev[o.application_round_id]) prev[o.application_round_id] = [];
          prev[o.application_round_id].push(o);
        }
      })
      return prev;
    }, {})


    return (
      <Aux>
        <Collapse isOpen={this.props.isOpen }>
          <Row className="no-gutters">
            <Col xs={12} className="r-bg--blue-1 p-3 mb-3">
            <Row>
              <Col xs={2}>
                <h1 className="r-subheading-3">Application Details</h1>
              </Col>
            </Row>
              <Row>
                <Col xs={2} className="border-right border-secondary">
                  <Row>
                    <Col>
                      <Card className="w-100 mt-2">
                        <CardBody>
                          <h1 className="r-subheading-3 d-inline-block">Application</h1><Button color="link  float-right" onClick={this.toggleEditJobModal}><i className="fi flaticon-edit"></i></Button>
                          {jobData.receive_status===1 && <p className="r-text--sm r-text--grey-5">{moment(jobData.receive_on).format("Do MMM YYYY")}</p>}
                          <h1 className="r-subheading-4 pt-3 d-inline-block">Published</h1><div className="float-right mt-3"><Switch isOn={jobData.receive_status} handleToggle={() => this.setState({publish_status : !this.state.publish_status})} /></div>
                        </CardBody>
                      </Card>
                      <Card className="w-100 text-center selectable mt-2" style={{height: 120}}>
                        <CardBody>
                          <h1 className="r-heading-2">{applicationData.applied.length}</h1>
                          <h1 className="r-subheading-3 pt-3">Applicants</h1>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col xs={10}>
                  <Row>
                    <Col xs={2}>
                      <Card className="w-100 text-center selectable mt-2" style={{height: 120}}>
                        <CardBody>
                          <Link to={"/campus/"+campusID+"/application/"+applicationID+"/job/"+jobId+"/round/0"} >
                            <h1 className="r-heading-2 text-dark">{(roundWiseApplicants["0"] || []).length}</h1>
                          </Link>
                          <h1 className="r-subheading-3 pt-3">Initial Round</h1>
                        </CardBody>
                      </Card>
                    </Col>
                    {
                      (applicationData.rounds || []).map(round => 
                        <Col xs={2}>
                          <Card className="w-100 text-center selectable mt-2" style={{height: 120}}>
                            <CardBody>
                              <Link to={"/campus/"+campusID+"/application/"+applicationID+"/job/"+jobId+"/round/"+round.application_round_id} >
                                <h1 className="r-heading-2 text-dark">{(roundWiseApplicants[round.application_round_id] || []).length}</h1>
                              </Link>
                              <h1 className="r-subheading-3 pt-3">{round.round_name}</h1>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    }
                  </Row>
                  <Row>
                  <Col xs={12} className="text-right mt-3">
                      
                      <Button onClick={()=>this.downloadCv()} color="dark" className="r-subheading-3 my-2 mr-3">Send CV's to email<i className="ml-2 fi flaticon-file"/></Button>
               
                      <Button onClick={()=>this.downloadExcel()} color="dark" className="r-subheading-3 my-2 mr-3">Excel<i className="ml-2 fi flaticon-file"/></Button>
             
                      <Button onClick={()=>this.downloadAnswers()} color="dark" className="r-subheading-3 my-2">Answers<i className="ml-2 fi flaticon-file"/></Button>
                
                </Col>
                  </Row>
                </Col>
                <Col xs={10}>
                  <div className="r-bg--light">
                    {/* <Row>
                      <Col>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({ active : activeTab === '1' })}
                              onClick={() => this.setState({activeTab : '1'})}
                            >
                              Manage Evaluation
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: activeTab === '2' })}
                              onClick={() => this.setState({activeTab : '2' , selectTab : 2})}
                            >
                              Evaluator Dashboard
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} >
                          <TabPane tabId="1" className="p-3">
                            <Link to={"campus/"+campusID+"/application/"+applicationID+"/job/"+jobId+"/round/0"} >
                              <Button className="p-2 px-4 ">
                                    Start Evaluation
                              </Button>
                            </Link>
                            <Button color="blue" className="p-2 px-4" onClick={()=>this.handleStartevaluation()}>
                                  Add Evaluators
                            </Button>
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row> */}
                  {selectTab==1?
                    <Aux>
                    <div className="p-3 m-3 border">
                    <Row>
                      <Col xs={6}>
                    <Row>
                      <Col className="px-4 py-3">
                        <span>Enter email addresses to add Evaluators</span>
                      </Col>
                    </Row>
                    <div className="">
                      <Row>
                      <Col xs={12}>
                        <Button className=" r-subheading-4 border p-2 mb-2" color="brandB" onClick={()=>this.setState({evaluatorInfoModal : true})}><i className="fi flaticon-plus" /> Add New </Button>
                      </Col>
                        {emailArray.map(email=>
                        <Col xs="auto" key={email.email}>
                          <div className=" border r-bg--grey-4 r-text--light mb-2">
                            <span className="p-2">
                              {email.email}
                              <Button onClick={()=>this.deleteEvaluatorEmail(email.email)}>
                                <i className="fi flaticon-plus-3" />
                              </Button>
                            </span>
                          </div>
                        </Col>)}
                      </Row>
                    </div>
                    <Modal zIndex="99999" isOpen={evaluatorInfoModal} toggle={()=>this.setState({evaluatorInfoModal : false})} >
                      <ModalHeader toggle={()=>this.setState({evaluatorInfoModal : false})} > <span className="r-subheading-3">Evaluator Details</span> </ModalHeader>
                      <ModalBody>
                          <form onSubmit={this.handleEmailInput} id="emailForm" >
                            <Row>
                              <Col xs={6}>
                                <Row>
                                  <Col>
                                    <label><span className="r-text--red-1">*</span>First Name</label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mb-2">
                                    <InputField type="text" placeholder="First Name"  name="firstName" className="border w-100" />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={6}>
                                <Row>
                                  <Col>
                                    <label>Last Name</label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mb-2">
                                    <InputField type="text" placeholder="Last Name"  name="lastName"  className="border w-100" />
                                  </Col>
                                </Row>
                              </Col>
                              </Row>
                            <Row>
                              <Col>
                                <label><span className="r-text--red-1">*</span>Email</label>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-2 w-100">
                                <InputField type="email" placeholder="Email"  name="email" className="border w-100" />
                              </Col>
                            </Row>
                            <Row>
                              <Col align="end">
                                <Button color="dark">Submit</Button>
                              </Col>
                            </Row>
                          </form>
                      </ModalBody>
                    </Modal>
                      <Row>
                        <Col>
                          {emailArray.length>0?<Button color="link-blue" className="m-2" onClick={()=>this.createEvaluatorAccount()}><span className="r-subheading-3">Send</span></Button> : null}
                        </Col>
                      </Row>
                      </Col>
                      <Col xs={6}>
                      <Row>
                        <Col className="px-4 py-3">
                          <span>Select from existing Evaluators</span>
                        </Col>
                      </Row>
                      <div className="evaluator__table pt-2">
                        <Table className="text-center">
                          <thead>
                            <tr>
                              <th><input type="checkbox" name="selectAll" /></th>
                              <th>Evaluators</th>
                            </tr>
                          </thead>
                          <tbody>
                          {evaluatorList.map(evaluator=>{
                            return(
                              <tr key={evaluator.user_id}>
                                <td><input type="checkbox" onChange={this.handleEvaluatorAccess} name={evaluator.user_id} defaultChecked={evaluator.accessDetails && Object.keys(evaluator.accessDetails).length>0 && evaluator.accessDetails.evaluation_access_id ?true : false} value={evaluator.accessDetails && Object.keys(evaluator.accessDetails).length>0?evaluator.accessDetails.evaluation_access_id : ''} /></td>
                                <td>{getFullName(evaluator.userDetails)}</td>
                              </tr>
                            )
                          })}
                          </tbody>
                        </Table>
                      </div>
                      </Col>
                      </Row>
                      </div>
                      </Aux>
                      : null}
                      {activeTab==2? <EvaluatorAllocation applicationData={applicationData.applied} evaluatorDetails={evaluatorList} /> : null}
                      </div>
                  </Col>
              </Row>
            </Col>
          </Row>
        </Collapse>
        <JobModal isOpen={isOpenJobEdit}  job={jobData} toggleModal={this.toggleEditJobModal}/>
      </Aux>

    )
  }
}
